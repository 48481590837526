import React, { Component } from "react";
import Layout from "../layout";
import Abt1 from "../images/abt1.jpg";
//import Abt2 from "../images/abt2.jpg";
import Target from "../images/target.svg";
import Vision from "../images/vision.svg";
import FeaturedImage from '../images/aboutpage.jpg';
import Hero from "../sections/hero";
import { PhoneIcon, MailIcon,ThumbUpIcon } from '@heroicons/react/outline';
import { Link } from 'gatsby';

class AboutPage extends Component {
  render() {
    return (
      <Layout>
          <Hero image={FeaturedImage}>
          <div className="sm:text-center lg:text-left">
            <h1 className="h1_tag">
              <span className="block xl:inline">We're tech partner of</span>{' '}
              <span className="block text-blue-600 xl:inline">Your Innovations</span>
            </h1>
            <p className="p_tag">
              Vinkas is a provider of IT consulting and software development services. We have helped organizations and companies improve business performance &amp; enhance their competitiveness.
            </p>
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <a
                  href="#"
                  className="btn-lg text-white bg-blue-600 hover:bg-blue-700"
                >
                  <PhoneIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  Call us
                </a>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <Link
                  to="/contact"
                  className="btn-lg text-blue-700 bg-blue-100 hover:bg-blue-200"
                >
                  <MailIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  Send message
                </Link>
              </div>
            </div>
          </div>
        </Hero>
        <div class="row bg-slate-100">
            <div className="container with-padding">
                <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
                      <div class="flex gap-2 pb-4">
                        <img
                          className="h-ful w-full object-cover rounded-xl"
                          src={Abt1} 
                          alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
                        />
                        
                      </div>  
                        <div class="pb-4">
                          <h4 class="pt-8 text-2xl font-extrabold text-slate-900 tracking-tight sm:text-3xl pb-4">Raising the Standards of Digital Innovation Requires a Lot More Than Sound Tech Skills.</h4>
                          <p class="pb-4 text-lg text-slate-500">We are a full-stack product engineering company that is committed to building world-class digital products. At Pyramidion, we place immense value on trust, collaboration and going the extra mile to ensure excellence. We believe our responsibility doesn’t end with just delivering an app. We constantly strive to help you navigate all the intricacies of digital transformation to scale your business. You can rely on us to hear you out and suggest solutions that fit your requirements as closely as possible rather than following standard workflows.</p>
                            <div class="text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8">
                                <div>
                                  <p class="about_paragraph">
                                  8+
                                  </p>
                                  <p class="about_subparagraph">
                                  Years of Experience
                                  </p>
                                </div>
                                <div class="mt-10 sm:mt-0">
                                  <p class="about_paragraph">
                                  120+
                                  </p>
                                  <p class="about_subparagraph">
                                  Experience of Developers
                                  </p>
                                </div>
                                <div class="mt-10 sm:mt-0">
                                  <p class="about_paragraph">
                                  250+
                                  </p>
                                  <p class="about_subparagraph">
                                  Happy Clients
                                  </p>
                                </div>
                          </div>
                        </div>
                        <div class="about_div">
                          <div class="about_flex">
                            <img
                              className="h-12 w-12"
                              src={Vision} 
                              alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
                            />
                            <h4 class="about_h4">Vision</h4>
                          </div>
                          <p class="pb-4 text-lg text-slate-500">Enabling businesses to leverage our innovative, reliable, and best-of-breed technology solutions for sustainable business growth and success.</p>
                        </div>
                        <div class="about_div">
                          <div class="about_flex">
                            <img
                              className="h-12 w-12"
                              src={Target} 
                              alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
                            />
                            <h4 class="about_h4 pb-4">Mission</h4>
                          </div>
                          
                          <div class="flex pb-2">
                        <div class="flex-shrink-0">
                          <div class="about_subflex">
                            <ThumbUpIcon className="h-6 w-6" />
                          </div>
                        </div>
                        <div class="ml-4">
                          <p class="about_ptag">
                          To relentlessly drive quality, value, and delivery of our diverse IT-related services and products
                          </p>
                        </div>
                      </div>
                      <div class="flex pb-2">
                        <div class="flex-shrink-0">
                          <div class="about_subflex">
                            <ThumbUpIcon className="h-6 w-6" />
                          </div>
                        </div>
                        <div class="ml-4">
                          <p class="about_ptag">
                          Be a process-driven, professionally managed, and profitable organization.
                          </p>
                        </div>
                      </div>
                      <div class="flex pb-2">
                        <div class="flex-shrink-0">
                          <div class="about_subflex">
                            <ThumbUpIcon className="h-6 w-6" />
                          </div>
                        </div>
                        <div class="ml-4">
                          <p class="about_ptag">
                          Become a great place to work where people are inspired, motivated and happy.
                          </p>
                        </div>
                      </div>
                          
                        </div>
                </div>
            </div>
            </div>
      </Layout>
    )
  }
}

export default AboutPage;
